<script>
import { defineComponent } from "vue";
import { get } from "vuex-pathify";
import lodashGet from "lodash/get";
import CampaignProgramEnum from "@/libs/CampaignProgramEnum";
import { clientSessionStorage, default as clientStorage } from "@/libs/clientStorage";
import lodashIsEmpty from "lodash/isEmpty";

export default defineComponent({
    name: "MfaRedirectionMixin",
    props: {
        fullWidth: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        $_pinValidated: get("context/pinValidated"),
        $_isMember: get("context/isMember"),
        $_isLoggedIn: get("context/isLoggedIn"),
        $_userExistForProspect: get("context/userExistForProspect"),
        $_isMemberDisabled: get("context/isMemberDisabled"),
        $_autoSendMFA: get("context/autoSendMFA"),
        $_firstName: get("context/firstName"),
        $_featureFlags: get("context/featureFlags"),
        $_programId: get("campaign/programId"),
        $_isNonPinnedReducedSignUpFlowEnabled() {
            return lodashGet(this.$_featureFlags, "NON_PINNED_REDUCED_SIGN_UP_FLOW", false) || false;
        },
        $_pinInUrl() {
            return lodashGet(this.$route.query, "pin");
        },
        $_userExist() {
            if (this.$_isMember) {
                return this.$_isMemberDisabled;
            } else {
                return !!this.$_userExistForProspect;
            }
        },
        $_noPinProspect() {
            return lodashGet(this.$route.query, "noPinProspect");
        },
        $_isBMWProgram() {
            return CampaignProgramEnum.BMW.indexOf(this.$_programId) !== -1;
        },
        $_hasFirstName() {
            return !lodashIsEmpty(this.$_firstName);
        },
        $_displayNoPinProspectWelcome() {
            let noPinTrue = this.$_noPinProspect === true || this.$_noPinProspect === "true";
            let noPinPresent = this.$_noPinProspect === null;
            return !this.$_hasFirstName && (noPinTrue || noPinPresent);
        },
    },
    methods: {
        // TODO: MOVE THIS LOGIC TO BACKEND
        _mixin_goToPageAction() {
            if (this.$_displayNoPinProspectWelcome) {
                window.location = "account/register";
            }

            if (this.$_pinValidated && !this.$_userExist) {
                if (this.$_autoSendMFA) {
                    if (this.$_pinInUrl) {
                        clientStorage.removeItem("mfaCode");
                    }
                    window.location = "profile/verify-access?pin=" + this.$_pinInUrl;
                } else {
                    window.location = "profile/verify";
                }
            } else if (this.$_userExist && !this.$_isLoggedIn) {
                window.location = "/account/login";
            } else if (this.$_isLoggedIn) {
                if (this.$_isBMWProgram) {
                    // For BMW redirect to SRP page
                    this.$router.push({ path: "/listings" });
                } else {
                    this.$router.push({ path: "/profile/welcome-back" });
                }
            } else if (this.$_isNonPinnedReducedSignUpFlowEnabled) {
                window.location = "account/register";
            } else {
                window.location = "profile/no-pin-welcome";
            }
        },
        _mixin_handleRedirectionsWithFilters(filters = null) {
            if (filters) {
                clientSessionStorage.setItem("SRP_FILTERS", JSON.stringify(filters));
            }
            this._mixin_goToPageAction(); // MODIFIED IN MfaRedirectionMixin
        },
    },
});
</script>
